import axios from "axios";
import { indonesia_payment, members } from "../../js/path";
import moment from "moment";
import _ from "lodash";

const apiEndpoint = process.env.VUE_APP_INDONESIA_PAYMENT;

export default {
  data() {
    return {
      screenshot: "",
      message: "",
      params: "",
      filter: null,
      pageNo: null,
      tableData: [],
      sortBy: "id",
      sortDesc: true,
      filterOn: [],
      currentPage: 1,
      fields: [
        {
          key: "id",
          visible: true,
        },
        {
          key: "member_name",
          label: "Member",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "order_items",
          label: "Title",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "order_number",
          label: "Order Number",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "order_id",
          label: "Transaction ID",
          visible: true,
          class: "c_touchpt_header",
        },
        {
          key: "payment_status",
          class: "text-center c_touchpt_header",
          label: "Payment Status",
          visible: true,
        },
        {
          key: "amount",
          visible: true,
          label: "Actual Amount",
          class: "c_touchpt_header",
        },
        {
          key: "coupon_code",
          label: "Coupon Code",
          visible: true,
          class: "c_touchpt_header",
        },
        {
          key: "taxable_amount",
          label: "Taxable Amount",
          visible: true,
          class: "c_touchpt_header",
        },
        {
          key: "tax",
          visible: true,
          class: "c_touchpt_header",
        },
        {
          key: "coupon_discount",
          label: "Coupon discount",
          visible: true,
          class: "c_touchpt_header",
        },
        {
          key: "total_amount",
          label: "Total Amount",
          visible: true,
          class: "c_touchpt_header",
        },
        {
          key: "order_date",
          label: "Order Date & Time",
          class: "c_touchpt_header",
          visible: true,
          formatter: (value) => {
            return moment(value).format("LLL");
          },
        },
        {
          key: "member_type",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "details",
          class: "text-center",
          visible: true,
        },
      ],
      activeTab: "all",
      isNotesPopup: false,
      isDetailsPopup: false,
      orderId: "",
      attachment: "",
      attachment_url: "",
      edit: {
        attachment_url: null,
      },
      tooltipMessage: "Click to view",
      memberList: [],
      getProduct: [],
      getProductItem: [],
      getCoupons: [],
      form: {
        member_id: "",
        product: "",
        product_item: "",
        coupon_id: "",
      },
    };
  },
  methods: {
    async submitData() {
      try {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please Fill The Required Fields",
          });
          return false;
        }
        this.$store.commit("loader/updateStatus", true);
        let url = `${apiEndpoint}${indonesia_payment?.manualPayment}`;
        let dataAppend = new FormData();
        if (this.form.member_id) {
          dataAppend.append(
            "user_reference_id",
            this.form?.member_id?.member_id
          );
        }
        dataAppend.append("user_reference_type", "medisage");
        if (this.form.product) {
          dataAppend.append("product_id", this.form.product?.id ?? "");
        }
        if (this.form.product_item) {
          dataAppend.append(
            "reference_type",
            this.form.product_item?.reference_type ?? ""
          );
          dataAppend.append("reference_id", this.form.product_item?.id ?? "");
          dataAppend.append("currency", this.form.product_item?.currency ?? "");
          dataAppend.append("price", this.form.product_item?.price ?? "");
          dataAppend.append("coupon_id", this.form.coupon_id?.id ?? "");
        }
        const request = await axios.post(url, dataAppend);
        const response = request?.data;
        if (response?.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: response?.message,
          });
          this.$router.push("/order");
        } else {
          console.log("inside else");
        }
      } catch (err) {
        const errStatus = err?.response?.status;
        if (errStatus == 422) {
          const errRes = err?.response?.data.response;
          const referenceId = errRes?.reference_id;
          const userReferenceId = errRes?.user_reference_id;
          console.log(referenceId, userReferenceId, "errRes");
          if (referenceId?.length > 0) {
            for (let index = 0; index < referenceId?.length; index++) {
              const element = referenceId[index];
              this.$store.commit("toast/updateStatus", {
                status: true,
                icon: "error",
                title: element ? element : "Something went wrong",
              });
            }
          }
          if (userReferenceId?.length > 0) {
            for (let index = 0; index < userReferenceId?.length; index++) {
              const element = userReferenceId[index];
              this.$store.commit("toast/updateStatus", {
                status: true,
                icon: "error",
                title: element ? element : "Something went wrong",
              });
            }
          }
        }
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchProducts() {
      const url = indonesia_payment?.getCouponProducts;
      const res = await this.getRequest(`${apiEndpoint}${url}`);
      if (res.status) {
        this.getProduct = res.response.data;
      }
    },
    async fetchProductItem() {
      const url = indonesia_payment?.getCouponItem;
      const data = await this.getRequest(
        `${apiEndpoint}${url}/${this.form.product?.id}`
      );
      if (data.status) {
        this.getProductItem = data.response.data;
      }
    },
    async fetchCoupons() {
      const url = indonesia_payment?.getCoupon;
      const res = await this.getRequest(
        `${apiEndpoint}${url}?product_id=${this.form?.product?.id}`
      );
      if (res.status) {
        console.log(res, "ress");
        this.getCoupons = res.response.data;
      }
    },
    productChange() {
      this.form.product_item = "";
      this.form.coupon_id = "";
    },
    fetchMemberList: _.debounce(async function (query) {
      this.$store.commit("loader/updateStatus", false);
      this.memberList = [];
      if (query != "" && query != null) {
        this.$store.commit("loader/updateStatus", true);
        const url = members.getMembers;
        const data = await this.getRequest(url + "?search=" + query);
        if (data.status) {
          this.memberList = data.response.data.map((member) => ({
            member_id: member.id,
            fnameAndLname: `${member.fname} ${member.lname} - ${member.mobile_number}`,
          }));
        }
      }
      this.$store.commit("loader/updateStatus", false);
    }, 1000),
    removeQueryParam() {
      let uri = window.location.toString();
      if (uri.indexOf("?") > 0) {
        let clean_uri = uri.substring(0, uri.indexOf("?"));
        window.history.replaceState({}, document.title, clean_uri);
      }
    },
    searchFor() {
      if (this.filter.length > 1) {
        this.fetchData(this.activeTab);
      } else if (this.filter.length == 0) {
        this.fetchData(this.activeTab);
        this.currentPage = 1;
      }
    },
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData(this.activeTab);
        }
      } else if (this.filter.length == 0) {
        this.removeQueryParam();
        this.currentPage = 1;
        this.$route.query.orderNumber = "";
        this.fetchData(this.activeTab);
      }
    },
    readFile(e, txt) {
      if (txt == "attachment") {
        this.attachment = e.target.files[0];
        if (this.attachment.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB",
          });
          this.$refs.attachment.reset();
          this.attachment_url = "";
          this.edit.attachment_url = "";
          this.activeTabis.attachment = "";
          return false;
        } else {
          this.attachment_url = URL.createObjectURL(this.attachment);
          this.edit.attachment_url = URL.createObjectURL(this.attachment);
        }
        return true;
      }
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData(this.activeTab);
        this.currentPage = 1;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchData(txt) {
      console.log(
        txt,
        "this.$route.query.orderNumber",
        this.$route.query.orderNumber
      );
      this.$store.commit("loader/updateStatus", true);
      const apiEndpoint = process.env.VUE_APP_INDONESIA_PAYMENT;
      let request = indonesia_payment?.getOrderList;
      if (txt == "all") {
        this.activeTab = txt;
        request = `${request}?page=${
          this.currentPage ?? 1
        }&q=${encodeURIComponent(this.filter ?? "")}&order_number=${
          this.$route.query.orderNumber ?? ""
        }`;
      } else {
        this.activeTab = txt;
        request = `${request}?order_status=${encodeURIComponent(
          this.activeTab ?? "all"
        )}&page=${this.currentPage ?? 1}&q=${encodeURIComponent(
          this.filter ?? ""
        )}&order_number=${this.$route.query.orderNumber ?? ""}`;
      }
      axios
        .get(`${apiEndpoint}${request}`)
        .then((response) => {
          const res = response?.data;
          if (res) {
            this.tableData = res?.response;
          }
          this.$store.commit("loader/updateStatus", false);
        })
        .catch((err) => {
          this.$store.commit("toast/updateStatus"),
            {
              status: true,
              icon: "error",
              title: "Something went wrong",
            };
          console.log(err);
          this.$store.commit("loader/updateStatus", false);
        });
    },
    openDetails(screenshot, note, attachment) {
      this.screenshot = screenshot;
      this.attachment = attachment;
      if (note != null) {
        this.message = note;
      }
      this.isDetailsPopup = true;
    },
    openNotes(id, screenshot) {
      console.log(id);
      this.orderId = id;
      this.isNotesPopup = true;
      this.screenshot = screenshot;
    },
    async handleClick(status) {
      this.$store.commit("loader/updateStatus", true);
      const apiEndpoint = process.env.VUE_APP_INDONESIA_PAYMENT;
      let request = indonesia_payment?.confirmPayment;
      let dataAppend = new FormData();
      dataAppend.append("order_id", this.orderId);
      dataAppend.append("note", this.message);
      if (this.attachment) {
        dataAppend.append("note_attachment", this.attachment);
      }
      if (status) {
        dataAppend.append("status", status);
      }
      axios
        .post(`${apiEndpoint}${request}`, dataAppend)
        .then((response) => {
          const res = response?.data;
          if (
            res?.response?.payment_status == 1 ||
            res?.response?.payment_status == 2
          ) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title:
                res?.response?.payment_status == 2
                  ? "Payment Rejected"
                  : "Payment Approved",
            });
            this.isNotesPopup = false;
            this.fetchData(this.activeTab, this.currentPage);
          }
          this.$store.commit("loader/updateStatus", false);
        })
        .catch((err) => {
          this.$store.commit("toast/updateStatus"),
            {
              status: true,
              icon: "error",
              title: "Something went wrong",
            };
          this.$store.commit("loader/updateStatus", false);
          console.log(err);
        });
    },
  },
  activeTab: {
    handler: function (value) {
      if (value == "trash") {
        let index = this.fields.findIndex((item) => item.key == "delete");
        this.fields.splice(index, 1);
        let edit_index = this.fields.findIndex((item) => item.key == "edit");
        this.fields.splice(edit_index, 1);
      } else if (value == "all" || value == "active") {
        if (this.fields[this.fields.length - 1].key != "delete") {
          this.fields.splice(this.fields.length, 0, { key: "edit" });
          this.fields.splice(this.fields.length, 0, { key: "delete" });
        }
      }
    },
  },
  watch: {
    "form.product": {
      handler: function () {
        this.getProductItem = [];
        this.fetchProductItem();
        this.getCoupons = [];
        this.fetchCoupons();
      },
    },
    currentPage: {
      handler: function (value) {
        if (typeof value != undefined) {
          this.fetchData(this.activeTab);
          this.pageNo = this.currentPage;
        }
      },
    },
  },
  created() {
    if (this.$route.name == "add-manual-payment") {
      this.fetchProducts();
    } else {
      this.fetchData(this.activeTab);
      if (this.$route.query?.orderNumber) {
        this.filter = this.$route.query?.orderNumber;
      }
    }
  },
};
