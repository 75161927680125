<template>
  <Layout>
    <PageHeader :title="title" :items="items"></PageHeader>
    <div class="row" id="article">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center mb-4">
              <div class="col-md-6">
                <div class="w-50">
                  <div class="input-group">
                    <input
                      name="title"
                      class="form-control bg-light border-0 small"
                      placeholder="Search Title"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      value=""
                      v-model="filter"
                      v-on:keyup="search"
                    />
                    <div class="input-group-append">
                      <button
                        id="myBtn"
                        class="btn btn-search"
                        @click="searchFor"
                      >
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="all-tabs">
                  <div
                    :class="activeTab == 'all' ? ' tab-options' : ''"
                    @click.prevent="
                      fetchData('all');
                      currentPage = 1;
                    "
                    class="mx-2 opt"
                  >
                    All
                  </div>
                  |
                  <div
                    :class="activeTab == '1' ? ' tab-options' : ''"
                    @click.prevent="
                      fetchData('1');
                      currentPage = 1;
                    "
                    class="mx-2 opt"
                  >
                    Success
                  </div>
                  |
                  <div
                    :class="activeTab == '0' ? ' tab-options' : ''"
                    @click.prevent="
                      fetchData('0');
                      currentPage = 1;
                    "
                    class="mx-2 opt"
                  >
                    Pending
                  </div>
                  |
                  <div
                    :class="activeTab == '3' ? ' tab-options' : ''"
                    @click.prevent="
                      fetchData('3');
                      currentPage = '1';
                    "
                    class="mx-2 opt"
                    style="white-space: nowrap"
                  >
                    Waiting for approval
                  </div>
                  |
                  <div
                    :class="activeTab == '2' ? ' tab-options' : ''"
                    @click.prevent="
                      fetchData('2');
                      currentPage = '1';
                    "
                    class="mx-2 opt"
                  >
                    Failed
                  </div>
                  <template v-if="can('add-manual-payment')">
                    |
                    <div class="d-flex align-items-center">
                      <svg
                        class="svg-inline--fa fa-table mx-2"
                        height="16px"
                        width="16px"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fa"
                        data-icon="table"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64v-96h160v96zm0-160H64v-96h160v96zm224 160H288v-96h160v96zm0-160H288v-96h160v96z"
                        ></path>
                      </svg>
                      <router-link
                        :to="{ name: 'add-manual-payment' }"
                        class="opt"
                        style="white-space: nowrap"
                        >Add Manual Payment</router-link
                      >
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="table-responsive mb-4">
              <b-table
                striped
                bordered
                :items="tableData.data"
                :fields="fields"
                :sort-by.sync="sortBy"
                :per-page="0"
                :current-page="currentPage"
                :sort-desc.sync="sortDesc"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                class="table-bordered table-hover"
              >
                <template v-slot:cell(coupon_code)="row">
                  <span class="text-center">
                    <template v-if="row.item.coupon !== null">
                      <span class="">{{ row.item.coupon.code }}</span>
                    </template>
                    <template v-else>
                      <span class="">---</span>
                    </template>
                  </span>
                </template>
                <template v-slot:cell(coupon_discount)="row">
                  <span class="text-center">
                    <template v-if="row.item.coupon !== null">
                      <span class="">{{ row.item.coupon.discount }}</span>
                    </template>
                    <template v-else>
                      <span class="">---</span>
                    </template>
                  </span>
                </template>
                <template v-slot:cell(details)="row">
                  <b-button
                    @click="
                      openDetails(
                        row?.item?.scrennshot,
                        row?.item?.payment?.note,
                        row?.item?.payment?.note_attachment
                      )
                    "
                    class="badge badge-secondary"
                    :disabled="row?.item?.currency == 'INR'"
                  >
                    View Details
                  </b-button>
                </template>
                <template v-slot:cell(payment_status)="row">
                  <div class="d-flex justify-content-center align-items-center">
                    <b-button
                      v-if="
                        row.item.payment_status == 3 ||
                        row.item.payment_status == 2
                      "
                      :class="`${
                        row.item.payment_status == 3
                          ? 'bg-primary'
                          : row.item.payment_status == 2
                          ? 'bg-danger'
                          : ''
                      }`"
                      class="p-1"
                      v-b-modal="'noteModal'"
                      @click="openNotes(row.item.id, row.item.scrennshot)"
                      style="white-space: nowrap"
                    >
                      <span v-if="row.item.payment_status == 3"
                        >Waiting for approval</span
                      >
                      <span v-if="row.item.payment_status == 2">Failed</span>
                    </b-button>
                    <b-button
                      v-else
                      :class="`${
                        row.item.payment_status == 0
                          ? 'bg-warning indonesia-payment-na'
                          : row.item.payment_status == 1
                          ? 'bg-success indonesia-payment-na'
                          : row.item.payment_status == 2
                          ? 'bg-danger indonesia-payment-na'
                          : ''
                      }`"
                      class="p-1"
                    >
                      {{
                        row.item.payment_status == 0
                          ? "Pending"
                          : row.item.payment_status == 1
                          ? "Paid"
                          : row.item.payment_status == 2
                          ? "Failed"
                          : "-"
                      }}
                    </b-button>
                  </div>
                </template>
                <template v-slot:cell(order_items)="row">
                  <template v-for="(item, index) in row.item.order_items">
                    <div :key="index" v-if="item.title">
                      <router-link
                        :to="{
                          name: 'order-item',
                          params: { id: item.id },
                        }"
                      >
                        <span
                          style="
                            border-bottom: 1px solid #5664d2;
                            word-break: break-all;
                          "
                          >{{ item.title }}</span
                        >
                      </router-link>
                    </div>
                    <span v-else :key="index">---</span>
                  </template>
                </template>
                <template v-slot:cell(order_id)="row">
                  <template v-if="row.item.payment">
                    <router-link
                      v-if="row.item.payment.transaction_uuid"
                      :to="{
                        name: 'payment',
                        query: { uid: row.item.payment?.transaction_uuid },
                      }"
                    >
                      <span
                        style="
                          border-bottom: 1px solid #5664d2;
                          word-break: break-all;
                        "
                      >
                        {{ row.item.payment.transaction_uuid }}
                      </span>
                    </router-link>
                    <span v-else>---</span>
                  </template>
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(order_number)="row">
                  <span v-if="row.item.order_number">
                    {{ row.item.order_number }}
                  </span>
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(member_name)="row">
                  <span v-if="row.item.member != null">
                    <router-link
                      :to="{
                        name: 'member-list',
                        query: {
                          contactNumber: row.item.member?.mobile_number,
                        },
                      }"
                    >
                      <span
                        style="
                          border-bottom: 1px solid #5664d2;
                          word-break: break-all;
                        "
                      >
                        {{ row.item?.member?.fname }}
                        {{ row.item?.member?.lname }}
                      </span>
                    </router-link>
                  </span>
                  <span v-else>---</span>
                </template>
              </b-table>
            </div>
            <template v-if="tableData.total_pages > 1">
              <div class="data_paginate">
                <div
                  class="dataTables_paginate paging_simple_numbers d-flex justify-content-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      v-model="currentPage"
                      first-number
                      last-number
                      :total-rows="tableData.total"
                      :total-pages="tableData.total_pages"
                      :per-page="tableData.per_page"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </template>
            <div class="no_content py-4" v-if="tableData?.data?.length == 0">
              <div style="display: grid; justify-items: center">
                <img src="../../../../../public/noData.svg" alt="" />
                <span class="no_content_text">No Data Available</span>
              </div>
            </div>
            <b-modal
              id="noteModal"
              size="lg"
              v-model="isNotesPopup"
              no-close-on-backdrop
              centered
              title="Confirmation"
            >
              <div class="p_screenshot">
                <div class="d-flex justify-content-between">
                  <h5 class="mb-2">Screenshot of Payment</h5>
                  <p
                    class="badge badge-success p-1"
                    style="cursor: pointer"
                    @click="show(screenshot)"
                    v-if="screenshot"
                  >
                    View Screenshot
                  </p>
                </div>
                <div class="p_viewer" v-if="screenshot">
                  <viewer :images="[screenshot]">
                    <img
                      :src="`${screenshot}`"
                      alt=""
                      height="100%"
                      width="100%"
                    />
                  </viewer>
                </div>
                <div class="no_content" v-else>
                  <div style="display: grid; justify-items: center">
                    <img src="../../../../../public/noData.svg" alt="" />
                    <span class="no_content_text">No Screenshot Available</span>
                  </div>
                </div>
              </div>
              <template v-slot:modal-footer class="p-0">
                <div class="p_screenshot">
                  <b-form-group class="mb-4">
                    <h5 class="mb-3" for="image">Attachment</h5>
                    <b-form-file
                      id="image"
                      accept="image/*"
                      placeholder="Choose a file or drop it here..."
                      @change="readFile($event, 'attachment')"
                      ref="attachment"
                    ></b-form-file>
                    <template v-if="attachment_url">
                      <img
                        :src="attachment_url"
                        width="128px"
                        height="128px"
                        ref="attchment_url"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                  </b-form-group>
                  <b-input-group id="person2-form" class="">
                    <h5 class="mb-3">Note</h5>
                    <b-form-textarea
                      id="message"
                      v-model="message"
                      placeholder="Type note [Eg: Payment successful]"
                      rows="4"
                      class="mb-3 p_screenshot_ta"
                    >
                    </b-form-textarea>
                  </b-input-group>
                  <div class="d-flex justify-content-end">
                    <b-button
                      @click="handleClick(1)"
                      type="submit"
                      class="bg-primary mr-2"
                      >Confirm</b-button
                    >
                  </div>
                </div>
              </template>
            </b-modal>
            <b-modal
              id="details"
              size="lg"
              v-model="isDetailsPopup"
              no-close-on-backdrop
              centered
              hide-header-close
              ok-only
              ok-title="Close"
              title="Details"
            >
              <div class="pb-4 p_screenshot">
                <div style="border-bottom: 1px solid #c0c7d3" class="pb-4">
                  <div class="d-flex justify-content-between">
                    <h5 class="mb-2">Payment Screenshot</h5>
                    <p
                      class="badge badge-success p-1"
                      style="cursor: pointer"
                      @click="show(screenshot)"
                      v-if="screenshot"
                    >
                      View Screenshot
                    </p>
                  </div>
                  <div class="p_viewer" v-if="screenshot">
                    <viewer :images="[screenshot]">
                      <img
                        :src="`${screenshot}`"
                        alt=""
                        height="100%"
                        width="100%"
                      />
                    </viewer>
                  </div>
                  <div class="no_content" v-else>
                    <div style="display: grid; justify-items: center">
                      <img src="../../../../../public/noData.svg" alt="" />
                      <span class="no_content_text"
                        >No Screenshot Available</span
                      >
                    </div>
                  </div>
                </div>
                <div style="border-bottom: 1px solid #c0c7d3" class="pb-4">
                  <div class="d-flex justify-content-between mt-4">
                    <h5 class="mb-2">Note Attachment</h5>
                    <p
                      class="badge badge-success p-1"
                      style="cursor: pointer"
                      @click="show(attachment)"
                      v-if="attachment != null"
                    >
                      View Attachment
                    </p>
                  </div>
                  <div class="p_viewer" v-if="attachment != null">
                    <viewer :images="[attachment]">
                      <img
                        :src="`${attachment}`"
                        alt=""
                        height="100%"
                        width="100%"
                      />
                    </viewer>
                  </div>
                  <div class="no_content" v-else>
                    <div style="display: grid; justify-items: center">
                      <img src="../../../../../public/noData.svg" alt="" />
                      <span class="no_content_text"
                        >No Note Attachment Available</span
                      >
                    </div>
                  </div>
                </div>
                <div>
                  <h5 class="mt-4 mb-2">Note</h5>
                  <p v-if="message">{{ message }}</p>
                  <div class="no_content" v-else>
                    <div style="display: grid; justify-items: center">
                      <img src="../../../../../public/noData.svg" alt="" />
                      <span class="no_content_text">No Note Available</span>
                    </div>
                  </div>
                </div>
              </div>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../../mixins/request";
import IndoMixin from "../../../../mixins/ModuleJs/payment-order";
import VueViewer from "v-viewer";
import "viewerjs/dist/viewer.css";
import Vue from "vue";
Vue.use(VueViewer);

export default {
  mixins: [MixinRequest, IndoMixin],
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Order",
      items: [
        {
          text: "Back",
          href: "/order",
        },
        {
          text: "List",
        },
      ],
    };
  },
  methods: {
    show(img) {
      this.$viewerApi({
        images: [img],
      });
    },
  },
};
</script>
